.client-data{
    padding-bottom: 1rem;
}

.title-grouped-table {
    text-align: center;
    color: #00ac6c;
    font-size: 30px;
    font-weight: 500;
}

.title-modal-preload {
    font-size: 20px;
    font-weight: 900;
    color: #464646;
}

.certificate-info {
    font-size: 16px;
    color: #464646;
}

.preload-message {
    background-color: rgba(2, 164, 18, 0.2);
}

.preload-message-warning {
    background-color: rgba(255, 193, 7, 0.61);
}

.btn-label-preload {
    position: relative;
    display: inline-block;
}

.btn-text-preload {
    padding-left: 1rem;
    line-height: 33px;
    font-size: x-large;
    margin: auto;
}

.btn-outline-success {
    color: #439c73 !important;
    border-color: #439c73 !important;
}

.btn-outline-success:hover {
    color: #439c73 !important;
    border-color: #439c73 !important;
    background-color: unset;
}

.selectAgreement {
    float: right;
    margin-right: 15px;
}

.custom-position {
    width: 16rem;
}

.MuiPaper-root {
    z-index: 0;
}

.resend-advice {
    width: 100%;
    font-size: 90%;
    text-align: right !important;
}

.reporting-button {
    z-index: 10;
    border-color: #ffc107;
    background-color: #fff;
    color: #000;
}

.table-upper-button {
    height: 45px;
}

.warning-msg-icon {
    height: 2.6rem;
    width: auto;
}
.warning-container {
    display: flex;
    background-color: gold;
    padding: 1rem 2rem;
}

.warning-container .icon-container {
    width: 4rem;
    display: inline-flex;
}

.warning-container .txt-container {
    width: calc(100% - 4rem);
    display: inline-flex;
    text-align: justify;
    font-weight: bold;
}

.paragraph-a{
    color: #5a6468 !important;
    padding: 1px 0 !important;
    font-weight: 700;
    text-decoration: underline !important;
    display: contents !important;
}

.paragraph-b{
    font-weight: bold;
    display: contents !important;
}

.display-link-form {
    color: #575757;
    text-decoration: underline;
    font-weight: 600;
}

