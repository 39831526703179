.form-control{
    width: 100% !important;
}

.form-control.large {
    width: 70% !important;
    display: inline-flex !important;
    height: 60px !important;
}

.font-validator-red{
    color: brown;
}

.input-group-icon{
    width: 24px !important;
    margin-right: 5px;
    margin-bottom: 0.5rem;
}

label {
    margin-top: 0.5rem;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}