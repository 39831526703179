.btn {
    border-radius: 0 !important;
}

.btn-label{
    left: 0;
}

.btn-text {
    padding-left: 1rem;
    line-height: 33px;
}

.green {
    background-color: #439c73 !important;
}

.with-icon {
    padding: 0 1rem 0 0;
    display: inline-flex;
    min-width: 15rem;
}


.with-icon.small-btn {
    min-width: 12rem;
}