.card{
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 115px;
    margin-bottom: 3rem;
    -webkit-box-shadow: 0px 1px 1px 1px rgba(173,171,173,1);
    -moz-box-shadow: 0px 1px 1px 1px rgba(173,171,173,1);
    box-shadow: 0px 1px 1px 1px rgba(173,171,173,1);
}

.card-component{
    margin-bottom: 2rem;
}

.card-header{
    background-color: #439c73 !important;
    text-align: center;
    padding: 30px 10px 30px 10px !important;
    color: #ffffff;
    border-radius: 1px;
    -webkit-box-shadow: 0px 2px 0px 0px rgba(173,171,173,1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(173,171,173,1);
    box-shadow: 0px 2px 0px 0px rgba(173,171,173,1);
    width: 100%;
}

.card-header{
    display: inline-table;
}

.card-description {
    font-family: Avenir;
    font-size: 23px;
}

.card-header h2{
    font-size: 1.6rem;
    font-family: Avenir;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; 
}

@media (max-width: 992px){
    .card{
        max-width: 95% !important;
    }

    .card-header h2{
        font-size: 1.6rem;
    }
}

@media (min-width: 768px){
    .card-separator{
        height: 17rem;
        background-color: darkgrey;
        width: 1px;
        position: absolute;
        left: -0.2rem;
        top: 4rem;
    }
}

@media (max-width: 767px){
    .product-info{
        border-bottom: 1px solid darkgray;
        padding-bottom: 3rem;
        margin-bottom: 2rem ;
    }
}