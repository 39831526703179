.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00ac6c;
    background-color: #00ac6c;
}

.quantity-products {
    width: 6rem !important;
    border-radius: 10%;
    text-align: center;
    margin-left: 2.5rem;
    height: 3.4rem;
}