
.btn-add-product {
    color: #000;
    border-color: #fc9231 !important;
    border-radius: 8px !important;
}

.descriptionProduct{
    font-size: 14px;
}

.icon-product-table{
    width: 8vh;
}

.nameProduct-shop{
    font-weight: bold;
    font-size: 15px;
}

.resendAlert{
    background-color: #ffd505;
    font-size: 25px;
    font-weight: bold;
    margin: 12px 0px 7px 0px;
}

.taxException{
    background-color: #ffd505;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 5px 0px 0px;
    margin: 15px 0px 15px 0px;
}

.sectionResendAlert {
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.textInfoHelp{
    margin: 10px;   
    font-size: 22px;
}

.cst-cart-height {
    min-height: calc(100% - 180px);
}

@-moz-document url-prefix() {
    .cst-cart-height {
      min-height: calc(100% - 120px) !important;
    }
}