.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*CUSTOM GLOBAL CSS*/

.container-descript {
  margin-bottom: 1rem;
}

.cst-container{
  padding: 1rem !important;
}

.cst-spinner{
  margin-left: auto;
  margin-right: auto;
  margin-top: 46vh;
  width: 4rem;
  height: 4rem;
}

.form-container {
  width: 100%;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0 !important;
}

.modal-dialog {
  max-width: 1000px !important;
  margin: 1.75rem auto;
}

.modal.show{
  opacity: 1 !important;
}

.page-container{
  display: block;
  width:100%;
  min-height: 100vh;
  background-image: url('./assets/img/background.png');
  background-size: cover;
}

#root{
  display: flex;
}

.sp-left{
  margin-left: 0.3rem;
}

.sidenav-container{
  display: block;
  min-height: 100vh;
}

.sidenav-container .rs-sidenav{
  min-height: 100vh;
}

.sidenav-container-full{
  display: block;
  min-height: 100vh;
}

.sidenav-container-full .rs-sidenav{
  min-height: 100vh;
}

.table td{
  vertical-align: middle !important;
}

.text-red {
  color: red !important;
}

@media (max-width: 600px) {
 
  .sidenav-container{
    width: 0%;
    transition: width 0.3s linear;
  }

  .sidenav-container-full{
    width: 100%;
    position: absolute;
    z-index: 1;
    transition: width 0.3s linear;
  }
} 

@media (min-width: 601px) and (max-width: 991px) { 
 
}

@media (min-width: 992px) { 

}

.checkTerms {
  margin-left: 20px;
}

.a:link termsLink {
  text-decoration: none;
}