.main-text {
    font-size: 24px;
}

.secondary-text {
    font-size: 20px;
}

.divider {
    border: 3px solid #439c73;
    border-radius: 5px;
    margin: 40px 0;
    width:100%;
}

.divider-minor {
    border: 1px solid gray;
    margin: 30px 0;
}