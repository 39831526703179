html {
    font-family: Avenir !important;
}

.presentation {
    align-content: center;
    color: red;
}

.text-footer {
    font-size: 20px;
}

.textIntro {
    font-size: 26px;
}

.green-text {
    color: #00ac6c;
    font-family: Avenir;
}

.root {
    width: 100%;
}

.heading {
    padding: 10px;
    fontSize: 20px;
    flexBasis: 33.33%;
    flexShrink: 0;
}

.eDescription {
    font-size: 20px;
}

.login {
    height: 60px !important;
}

.line {
    color: #00ac6c;
    position: absolute;
    align: center;
}

.font-validator-red {
    color: #ae3131;
    font-size: 14px;
    padding-top: 10px;
}

.forgetPass {
    color: #00ac6c;
    right: 0;
}

.forget {
    right: 0;
}

.btn-singin, .btn-registry {
    width: 30vh;
}

.hcount {
    height: 4vh;
    width: 4vh;
    margin-right: 55vh;
}

.newUser {
    height: 4vh;
    width: 4vh;
    margin-right: 40vh;
}

.section-title {
    margin: auto;
}

.vl {
    border-left: 3px solid #d4d4d4;
    height: 300px;
}

.hl {
    border-bottom: 4px solid #d4d4d4;
    width: 100%;
}

.hl-green {
    margin: auto;
    border-bottom: 3px solid #00ac6c;
    width: 97%;
}

.nameProduct-shop {
    font-weight: bold;
    font-size: 15px;
}

@media (max-width: 1040px) {

}

@media (min-width: 1040px) {
    .product-info {
        margin-bottom: 60px;
    }
}

.product-info {
    margin-bottom: 60px;
}

.nameProduct {
    font-weight: bold;
    margin: 10px 0 10px 0;
    height: 50px;
    line-height: 1.1;
}

.descriptionProduct {
    line-height: 1.4;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon-product {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 100px;

}

.priceUnity {
    color: #00ac6c;
}

.priceProduct {
    margin: 10px;
    color: #00ac6c;
    font-size: 20px;
    line-height: 1.4;
    height: 30px;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btnAdd {
    margin-top: 20px;
}

.quantity-products {
    width: 10vh;
    border-radius: 10%;
    text-align: center;
    margin-left: 2.5rem;
}

.text-input {
    width: 90%;
    height: 40px;
}

.total {
    align-items: center;
}

.total-price {
    color: #00ac6c;
    font-size: 25px;
    font-family: Arial;
}

.pay {
    margin-top: 35px !important;
}

@media (max-width: 1040px) {
    .quotation, .pay, .btn-singin, .btn-registry {
        /*width: 40vh;*/
        text-align: left;
        margin: 15px;
        border: transparent;
        -moz-box-shadow: inset 0px -5px 0px 0px #1f805b;
        -webkit-box-shadow: inset 0px -5px 0px 0px #1f805b;
        box-shadow: inset 0px -5px 0px 0px #1f805b;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #2c9c73), color-stop(1, #2c9c73));
        background: -moz-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: -webkit-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: -o-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: -ms-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: linear-gradient(to bottom, #2c9c73 5%, #2c9c73 100%);
        background-color: #2c9c73;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
    }

    .buttons-bar {
        padding: 10px;
        /*margin-left: 10%;*/
    }
}

@media (min-width: 1040px) {
    .quotation, .pay, .btn-singin, .btn-registry {
        /*width: 40vh;*/
        margin: 15px;
        border: transparent;
        -moz-box-shadow: inset 0px -5px 0px 0px #1f805b;
        -webkit-box-shadow: inset 0px -5px 0px 0px #1f805b;
        box-shadow: inset 0px -5px 0px 0px #1f805b;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #2c9c73), color-stop(1, #2c9c73));
        background: -moz-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: -webkit-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: -o-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: -ms-linear-gradient(top, #2c9c73 5%, #2c9c73 100%);
        background: linear-gradient(to bottom, #2c9c73 5%, #2c9c73 100%);
        background-color: #2c9c73;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
    }

    .buttons-bar {
        padding: 10px;
        /*margin-left: 15%;*/
    }
}

.btn-label {
    position: relative;
    left: -12px;
    display: inline-block;
    background: #2C9C72;
}

.btn-labeled {
    padding-top: 0;
    padding-bottom: 0;
    width: auto;
    min-width: 12rem;
}

.btn-labeled div {
    display: inline-block;
}

@media (max-width: 600px) {
    /*.btn-labeled{
        width: 150px;
    }*/
    .cst-table {
        display: block;
        width: 100%;
        overflow-x: auto;
    }
}

.iconbutton {
    max-width: 40px;
}


.btn-add-product {
    color: #000;
    border-color: #fc9231;
    border-radius: 8px;
}

.btn-add-product:hover {
    color: #212529;
    background-color: #fc9231;
    border-color: #fc9231;
}

.btn-add-product:focus, .btn-add-product.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-add-product.disabled, .btn-add-product:disabled {
    color: #fc9231;
    background-color: transparent;
}

.btn-add-product:not(:disabled):not(.disabled):active, .btn-add-product:not(:disabled):not(.disabled).active,
.show > .btn-add-product.dropdown-toggle {
    color: #212529;
    background-color: #fc9231;
    border-color: #fc9231;
}

.btn-add-product:not(:disabled):not(.disabled):active:focus, .btn-add-product:not(:disabled):not(.disabled).active:focus,
.show > .btn-add-product.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.sectionTitle {
    margin-top: 7vh;
    margin-bottom: 6vh;
}

.pseImage {
    width: 25vh;
    margin: 3vh;
}

.table-filter-button {
    z-index: 10;
    border-color: #ffc107;
    background-color: #fff;
    color: #000;
}

.input-icon {
    height: 35px;
}

.icon-btn-outline-warning {
    width: 30px;
    margin-right: 10px;
}

.clickable {
    cursor: pointer;
    color: #2C9C72;
    text-decoration: underline;
}

.form-control.login {
    width: 70% !important;
    display: inline-flex !important;
}

.pdb-1 {
    padding-bottom: 1rem;
}

.img-container img {
    margin-right: unset !important;
}

.section-title {
    width: 80%;
}

.heading {
    width: 100%;
}

.minW-18 {
    min-width: 18rem;
}

.options-container {
    padding-left: 2.5rem;
}

.modal-close {
    float: right;
}

.cst-file-input {
    padding: 0 !important;
    height: 3rem;
}

.btn-attachment {
    height: 3rem;
    width: 60%;
    margin: 0 !important;
    font-size: 16px;
}

.btn-attachment img {
    height: 3rem;
    max-width: 3rem;
}

.txt-prod-nf {
    font-size: 20px;
}

.d-grid {
    display: grid !important;
}

.cart-user-data {
    margin-bottom: 0rem;
    font-weight: bold;
    font-size: 15px;
}

.text-changePassword {
    font-size: 15px;
}

.cst-spinner {
    margin-top: 48vh;
    margin-left: 49%;
    color: #439c73 !important;
    height: 3rem;
    width: 3rem;
}

.cardsFinancial {
    margin: 0 auto 10px auto;
    max-width: 100%;
    border-radius: 0.25rem;
    box-shadow: 0px 1px 1px 1px rgba(173, 171, 173, 1) !important;
}

.margin-bottom-optional {
    margin-bottom: 10px !important;
}

.toolTipButton {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.buttonVoucher {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
}

.buttonVoucher:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.toolTipButton [aria-label] {
    position: relative;

}

.toolTipButton [aria-label]::before {
    content: attr(aria-label);
    display: block;
    position: absolute;
    top: 100%;
    width: 80px;
    padding: 3px;
    text-align: center;
    text-transform: none;
    font-size: 12px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    color: white;
    transform: translate(0, 20%);
    transition: all .2s ease-in-out .1s;
    z-index: 1;
}

.toolTipButton [aria-label]:hover::before {
    opacity: 1;
    transform: translate(0, -20%);
}

@media (max-width: 992px) {
    .cardsFinancial, .cardsCommercial {
        max-width: 95% !important;
    }
}

.CalendarContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}


.CalendarContainer .react-datepicker-wrapper {
    width: 86%;
}

.calendarSizeInput,
.CalendarContainer .react-datepicker-wrapper div {
    width: 100% !important;
}

.cardsCommercial {
    margin: 0 auto 10px auto;
    max-width: 70%;
    border-radius: 0.25rem;
    box-shadow: 0px 1px 1px 1px rgba(173, 171, 173, 1) !important;
}

.standardc, .agreementc {
    height: 4vh;
    width: 4vh;
    margin-right: 40vh;
}

.detailTables {
    margin: 0 !important;
    padding: 0 !important;
}

.actionSuspended {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.modal-msg{
    font-size: 1.2rem;
    padding: 1.5rem;
}

.modal-msg-large{
    font-size: 1.5rem;
    padding: 1.5rem;
}

.width-80 {
    width: 80% !important;
}

.switchAgreement {
    float: right;
}

.labelswitchAdmin {
    margin-right: 50px;
}


#billing {
    opacity: 0;
    transition: 1500ms;
}

.terms {
    overflow-y: scroll;
    height: 250px;
    width: 100%;
    border: 1px solid #DDD;
    padding: 10px;
}

.text-clickable {
    color: #575757;
    text-decoration: underline;
    font-weight: 600;
}

/* .paragraph-a{
    white-space: nowrap !important;
    padding: 1px 0 !important;
    font-weight: 700;
    text-decoration: underline !important;
    display: contents !important;
}

.paragraph-b{
    font-weight: bold;
    display: contents !important;
} */


.footer-container {
    font-size: 20px;
    background-color: #e7e7e7;
}