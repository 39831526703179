body {
  margin: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem !important;
  padding-right: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cst-body-height {
  min-height: calc(100% - 180px);
}


@-moz-document url-prefix() {
  .cst-body-height {
    min-height: calc(100% - 120px) !important;
  }
}