.header-modal{
    background-color: #f4912f;
    text-align: center;
    color: white;
    width: 100%;
}

button.modal-close{
    background-color: transparent;
    border-color: transparent;
    right: 0;
}

button.modal-close:hover {
    background-color: transparent;
    border-color: transparent;
}

.specifyIcon{
    margin-right: 0.3rem;
    width: 35px;
}

.subtotal{
    margin: 15px 0 15px 0;
}

.itemsCenter{
    margin-top: 50px;
}

.btn-submit-product {
    border-radius: 8px;
}

.imgSucces {
    margin: auto;
}

.SuccessIcon{
    margin: 10vh 5vh 10vh 5vh;
}

.information{
    width: 15px;
}

.lbl-container{
    width:100%;
}

.modal-form-lbl{
    font-size: 1.5rem;
}

.modal-title {
    text-transform: uppercase;
    width: 100%;
    font-size: 1.25rem;
    line-height: 3rem;
    margin-left: 40px;
}

.w-100{
    width:100%;
}
