#header-logo{
    width: 100%;
    padding: 1rem;
}

.sidenav-img{
    background-image: url("../../assets/img/logo.png") !important;
    background-size: 100% 100%;
    height: 110px;
}

@media (min-width: 601px) and (max-width: 991px) { 
    #header-logo{
        width: 100%;
        padding: 0.5rem;
    }
    .sidenav-img{
        background-image: url("../../assets/img/logo-min.png") !important;
        background-size: 100% 100%;
        height: 40px;
    }
}
  
@media (max-width: 600px) { 
    #header-logo{
        padding: 0rem;
    }

    .sidenav-img{
        background-image: url("../../assets/img/logo.png") !important;
        background-size: 100% 100%;
        height: 100px;
        margin: 1rem;
    }
}
  