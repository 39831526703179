html {
    font-family: Avenir !important;
}

.header{
    background-image: url('../../assets/img/fondo_v5.jpg');
    height: 200px;
    margin-bottom: -170px;
    background-size: cover;
}

.header-icon .rs-icon{
    color: #439c73;
    font-size:1.8rem;
    margin-right: 0 !important;
    margin-top: -5px;
}

.header-logo{
    margin: 2rem 50px 75px 10px;
    height: 70px;
    z-index: 1;
    position: absolute;
}

.nav-icons{
    margin-right: 10px;
    text-align: right;
    z-index: 0;
    position: absolute;
    right: 0;
}

.home{
    width: 25px;
    height: 25px;
    margin: 2px 10px 2px 10px;
}

.cart{
    width: 25px;
    height: 25px;
    margin: 2px 10px 2px 10px;
}

.logout{
    width: 25px;
    height: 25px;
    margin: 2px 10px 2px 10px;
}


.footer-logo{
    width: auto;
    max-height: 45px;
}

.cst-footer-container{
    border-top: 1px solid #989393;
}

.unset-card{
    box-shadow: unset;
    margin-bottom: unset;
    margin-top: unset;
    border: unset;
}

.cst-text-color{
    color: #212121;
}

@media (max-width: 600px) {
   
} 